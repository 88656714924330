import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Ehs from './components/ehs';
import ContactPage from './components/ContactPage';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize('G-41QLH62W9Y');

// Track page views
const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

const AppRoutes = () => {
  usePageTracking();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<LoginPage />} />

      {/* Private Routes */}
      <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
      <Route path="/ehs" element={<PrivateRoute element={<Ehs />} />} />
      <Route path="/contact" element={<PrivateRoute element={<ContactPage />} />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Container maxWidth="xl" sx={{ padding: 4 }}>
          {/* Header: Always visible */}
          <Header />

          {/* Main Content: Includes routes */}
          <Box sx={{ paddingTop: '1rem' }}>
            <AppRoutes />
          </Box>

          {/* Footer: Always visible */}
          <Footer />
        </Container>
      </Router>
    </AuthProvider>
  );
};

export default App;