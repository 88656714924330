import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';

const Benefits = () => (
  <Box
    sx={{
      backgroundColor: '#f9f9f9',
      padding: 3,
      borderRadius: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      marginBottom: 4,
      transition: 'transform 0.3s ease, boxShadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
      Employee Benefits
    </Typography>
    <List>
      <ListItem sx={{ paddingLeft: 0 }}>
        <Typography>Comprehensive healthcare</Typography>
      </ListItem>
      <ListItem sx={{ paddingLeft: 0 }}>
        <Typography>Retirement plans</Typography>
      </ListItem>
      <ListItem sx={{ paddingLeft: 0 }}>
        <Typography>Continuous training programs</Typography>
      </ListItem>
    </List>
  </Box>
);

export default Benefits;