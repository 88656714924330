import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const News = () => (
  <Box
    sx={{
      backgroundColor: '#f9f9f9',
      padding: 3,
      borderRadius: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      marginBottom: 4,
      transition: 'transform 0.3s ease, boxShadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
      Latest News
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      We hope this message finds you well. We are writing to inform you of an important change regarding our salary payment schedule that will take effect on January 1, 2025.
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      <strong>What is Changing?</strong> Starting from January 1, 2025, salaried team members' pay cycle will transition from a monthly payment schedule to a bi-weekly payment schedule. This means that you will receive your salary every two weeks instead of once a month.
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      <strong>Why Are We Making This Change?</strong> The decision to switch to a bi-weekly pay cycle is aimed at providing you with more frequent access to your earned wages. We believe this change will help improve financial planning and cash flow for our employees.
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      <strong>Key Details:</strong>
    </Typography>
    <List sx={{ color: 'text.primary', mb: 2 }}>
      <ListItem>
        <ListItemText primary="First Bi-Weekly Pay Date: January 17, 2025" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Pay Periods: The first pay period will be prorated and start January 1, 2025, to January 11, 2025. All other pay periods will cover two weeks, starting Sunday and ending on Saturday." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Pay Dates: Please see the additional page for details." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Payroll Deductions: Please note that deductions for benefits, taxes, and other withholdings will be adjusted accordingly to reflect the new pay schedule." />
      </ListItem>
    </List>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      <strong>What You Need to Do:</strong>
    </Typography>
    <List sx={{ color: 'text.primary', mb: 2 }}>
      <ListItem>
        <ListItemText primary="Review your current payroll information to ensure that all details are up to date." />
      </ListItem>
      <ListItem>
        <ListItemText primary="If you have any questions regarding this change or how it may affect your personal finances, please do not hesitate to reach out to your local HR department." />
      </ListItem>
    </List>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      We appreciate your understanding and cooperation as we make this transition. We believe that this change will ultimately benefit all employees and enhance your overall experience with Axillon Aerospace.
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      Thank you for your continued hard work and dedication.
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      Best Regards,
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary', mb: 2 }}>
      Becky Oakes
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.primary' }}>
      HR Director | Axillon Aerospace
    </Typography>
  </Box>
);

export default News;