import React from 'react';
import { Box, Typography, Grid, Link, Divider, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faClock, faHeart, faChartBar } from '@fortawesome/free-solid-svg-icons'; // Icons

const CorporateLinks = () => (
  <Box sx={{ marginBottom: 4 }}>
    <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
      Corporate Links
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Link
          href="http://usbipvscada01:8090/"
          color="primary"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: 8, fontSize: '1rem' }} />
          SCADA System
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link
          href="https://secure.bswift.com/default.aspx?abbrev=carbontopco"
          color="primary"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faHeart} style={{ marginRight: 8, fontSize: '1rem' }} />
          Benefits Portal
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link
          href="http://usbipvtableau/#/home"
          color="primary"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faChartBar} style={{ marginRight: 8, fontSize: '1rem' }} />
          Tableau (Analytics)
        </Link>
      </Grid>
    </Grid>
  </Box>
);

const SiteLinks = ({ siteName, links }) => (
  <Paper
    elevation={3}
    sx={{
      padding: 2,
      marginBottom: 4,
      borderRadius: 2,
    }}
  >
    <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
      {siteName}
    </Typography>
    <Grid container spacing={2}>
      {links.map((link) => (
        <Grid item xs={12} sm={6} key={link.name}>
          <Link href={link.url} color="primary" underline="hover" target="_blank" rel="noopener noreferrer">
            {link.icon && (
              <FontAwesomeIcon icon={link.icon} style={{ marginRight: 8, fontSize: '1rem' }} />
            )}
            {link.name}
          </Link>
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const HelpfulLinks = () => {
  const siteData = [
    {
      siteName: 'Baltimore',
      links: [
        { name: 'Work Schedule', url: '#baltimore-schedule' },
        { name: 'Local HR Policies', url: '#baltimore-hr' },
      ],
    },
    {
      siteName: 'Erlanger',
      links: [
        { name: 'Shift Roster', url: '#erlanger-roster' },
        { name: 'Holiday Calendar', url: '#erlanger-holidays' },
      ],
    },
    {
      siteName: 'Cincinnati',
      links: [
        { name: 'Training Portal', url: '#cincinnati-training' },
        { name: 'Employee Resources', url: '#cincinnati-resources' },
      ],
    },
    {
      siteName: 'Rockmart',
      links: [
        { name: 'Safety Guidelines', url: '#rockmart-safety' },
        { name: 'Maintenance Schedule', url: '#rockmart-maintenance' },
      ],
    },
    {
      siteName: 'Saltillo',
      links: [
        { name: 'Time Tracking', url: '#saltillo-time' },
        { name: 'Facility Map', url: '#saltillo-map' },
      ],
    },
    {
      siteName: 'San Diego',
      links: [
        {
          name: 'Timecard',
          url: 'http://usbipvm2msfdc:8080/unanet/action/home',
          icon: faClock, // FontAwesome icon for the link
        },
        { name: 'Office Directory', url: '#san-diego-directory' },
      ],
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        padding: 3,
        borderRadius: 2,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        marginBottom: 4,
        transition: 'transform 0.3s ease, boxShadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Typography variant="h4" sx={{ mb: 3, color: 'primary.main' }}>
        Helpful Links
      </Typography>
      <CorporateLinks />
      <Divider sx={{ my: 3 }} />
      {siteData.map((site) => (
        <SiteLinks key={site.siteName} siteName={site.siteName} links={site.links} />
      ))}
    </Box>
  );
};

export default HelpfulLinks;