import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from react-dom/client
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

// Get the root container
const container = document.getElementById('root');

// Create a root using ReactDOM.createRoot
const root = ReactDOM.createRoot(container);

// Render the app
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);