import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const KevinsKorner = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: '#a4d06a',
        padding: 3,
        borderRadius: 2,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        margin: '1em 0',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'center' : 'flex-start',
          gap: 2,
        }}
      >
        {/* Image Section */}
        <Box
          component="img"
          src="/Kevin.png" // Replace with the actual path to your image
          alt="Kevin smiling"
          sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        />

        {/* Text Section */}
        <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
          <Typography variant="h4" sx={{ mb: 1, color: 'primary.main' }}>
            Kevin's Corner
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            November 11, 2024
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.primary', whiteSpace: 'pre-line' }}>
            Dear Axillon Aerospace Team Members,

            As we observe Veterans Day, I would like to take a moment to express our heartfelt gratitude to those of you who have served in the military. Your dedication, sacrifice, and commitment to protecting our freedoms do not go unnoticed.

            We are proud to have you as part of Axillon Aerospace, and we appreciate the unique perspectives and skills you bring from your military experience. Thank you for your bravery, commitment, and values you uphold.

            Today, let us honor all veterans and reflect on the importance of their contributions to our country.

            I would also like to send a sincere thank you to the teams across the Rockmart, Baltimore, Erlanger, and Cincinnati sites for a warm welcome during the Town Hall communication visits last week. I hope Rob Hartmann and I were able to provide clarity around Axillon Aerospace as a standalone growth company.

            This week, several of the leadership team members and I will be traveling to our San Diego and Saltillo facilities. For me, new to Axillon Aerospace, these visits provide great insight into our capabilities as we focus on operational excellence and business development opportunities. Also this week, Tom Holst and Jessica Thomas will be leading the GM Kaizen event in Rockmart, and I commend the Rockmart team for the focus on continuous improvement and look forward to seeing the implementation of innovative ideas!

            I’m incredibly proud to be a part of Axillon Aerospace! As we discussed at the Town Hall meetings last week, we are a key partner to the world's leading aerospace and defense platforms, our team and our products keep fleets flying, and we play a key role in protecting our troops around the globe.

            To the veterans across Axillon Aerospace – We honor your service.

            From all of us on the Axillon leadership team, we look forward to the journey ahead!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default KevinsKorner;