import React from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'secondary.main',
        color: 'white',
        py: 4,
        mt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Divider sx={{ mb: 2, borderColor: 'white' }} />
        <Typography variant="h5" align="center" gutterBottom>
          Axillon Aerospace - Leading innovation in aerospace technology.
        </Typography>
        <Typography variant="body2" align="center">
          © {currentYear} Axillon Aerospace. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;