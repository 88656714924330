import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

const Ehs = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h2" gutterBottom>
        Environmental Health and Safety
      </Typography>

      <Typography variant="body1" paragraph>
        Axillon is committed to making a positive impact by improving the lives of team members, protecting the environment, and supporting local communities. This commitment not only reflects Axillon’s core values but also aims to contribute to the company’s success and generate positive results for customers and stakeholders.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Health and Safety
      </Typography>
      <Typography variant="body1" paragraph>
        Axillon prioritizes safety and is dedicated to ensuring that team members return home to their families, friends, and communities after each workday. The company sets clear expectations for operational safety excellence and provides team members with the tools and training necessary to identify and mitigate hazards in the work environment.
      </Typography>
      <Typography variant="body1" paragraph>
        All team members are encouraged to participate in Axillon’s safety culture. By establishing High Performance Teams focused on safety, Axillon strives to engage team members in enhancing safety performance, avoiding at-risk behaviors, and learning from everyday experiences. The company is committed to fostering a culture of engagement, ownership, recognition, and accountability.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Environmental Stewardship
      </Typography>
      <Typography variant="body1" paragraph>
        By simplifying complex manufacturing processes and implementing systems for operational control and performance, Axillon aims to deliver innovative products and solutions that address engineering challenges while safeguarding the environment.
      </Typography>
      <Typography variant="body1" paragraph>
        Axillon recognizes the importance of acting responsibly with the planet’s natural resources. As part of this commitment, the company will actively explore and implement solutions to reduce production waste, emissions, and the consumption of resources such as energy, water, and raw materials.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Compliance
      </Typography>
      <Typography variant="body1" paragraph>
        Axillon is dedicated to complying with all applicable environmental, health, and safety regulations in the regions where it operates. The company will periodically review its management systems and EHS performance to identify opportunities for improvement.
      </Typography>
      <Typography variant="body1" paragraph>
        Axillon is passionate about EHS performance and aims to collaborate with customers, suppliers, and community partners. The company will seek and respond to community and stakeholder feedback regarding environmental, health, and safety issues.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Our Commitments
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Achieving a zero-incident workplace." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Reducing the environmental impact of manufacturing operations." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Maintaining compliance with legal requirements." />
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        Strategies to fulfill these commitments are integrated into the corporate strategy, Axillon’s business system, and the company has established global EHS directives that outline minimum expectations for management and operational performance related to environmental, health, and safety measures. Across all aspects of its business, Axillon will strive for continuous improvement to identify new opportunities for better performance, reduce risks, and make a positive impact on the lives of team members, the environment, and the communities it serves.
      </Typography>

      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Button variant="contained" color="primary" href="/contact">
          Contact Our EHS Department
        </Button>
      </Box>
    </Box>
  );
};

export default Ehs;