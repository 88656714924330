import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1F3E76', // Deep blue
    },
    secondary: {
      main: '#7fb539', // Olive green
    },
    error: {
      main: '#d32f2f', // Red
    },
    warning: {
      main: '#ffa000', // Amber
    },
    info: {
      main: '#1976d2', // Light blue
    },
    success: {
      main: '#388e3c', // Green
    },
    background: {
      default: '#f8f9fa', // Light gray
    },
    text: {
      primary: '#1F3E76', // Deep blue
      secondary: '#333', // Dark gray
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#1F3E76', // Deep blue
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
      color: '#1F3E76', // Deep blue
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.4,
      color: '#1F3E76', // Deep blue
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: '#333', // Dark gray
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1F3E76', // Deep blue
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
});

export default theme;