import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faBuilding, faExclamationTriangle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const Support = () => (
  <Box
    sx={{
      backgroundColor: '#f9f9f9',
      padding: 3,
      borderRadius: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      marginBottom: 4,
      textAlign: 'center',
      transition: 'transform 0.3s ease, boxShadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
      Support Services ... coming soon!
    </Typography>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faDesktop} />}
        aria-label="IT Support Button"
        onClick={() => window.open('https://axillon.atlassian.net/servicedesk/customer/portal/1', '_blank')}
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          '&:hover': {
            backgroundColor: '#3091f2', // Darker shade of primary
            color: '#ffffff', // White text for contrast
          },
        }}
      >
        IT Support
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faBuilding} />}
        aria-label="Facilities Support Button"
        disabled
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          opacity: 0.5, // Visually indicate that it's disabled
          cursor: 'not-allowed', // Show a disabled cursor on hover
        }}
      >
        Facilities Support
      </Button>
      {/* Uncomment the code below to enable the Facilities Support button functionality */}
      {/* <Button
        variant="contained"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faBuilding} />}
        aria-label="Facilities Support Button"
        onClick={() => alert('Facilities Support clicked')}
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          '&:hover': {
            backgroundColor: '#4a4a4a', // Darker gray
            color: '#ffffff',
          },
        }}
      >
        Facilities Support
      </Button> */}
      <Button
        variant="contained"
        color="warning"
        startIcon={<FontAwesomeIcon icon={faExclamationTriangle} />}
        aria-label="Safety Incident Button"
        disabled
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          opacity: 0.5, // Visually indicate that it's disabled
          cursor: 'not-allowed', // Show a disabled cursor on hover
        }}
      >
        Safety Incident
      </Button>
      {/* Uncomment the code below to enable the Safety Incident button functionality */}
      {/* <Button
        variant="contained"
        color="warning"
        startIcon={<FontAwesomeIcon icon={faExclamationTriangle} />}
        aria-label="Safety Incident Button"
        onClick={() => alert('Safety Incident clicked')}
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          '&:hover': {
            backgroundColor: '#b36b00', // Darker shade of warning yellow
            color: '#ffffff',
          },
        }}
      >
        Safety Incident
      </Button> */}
      <Button
        variant="contained"
        color="success"
        startIcon={<FontAwesomeIcon icon={faShoppingCart} />}
        aria-label="Purchase Request Button"
        disabled
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          opacity: 0.5, // Visually indicate that it's disabled
          cursor: 'not-allowed', // Show a disabled cursor on hover
        }}
      >
        Purchase Request
      </Button>
      {/* Uncomment the code below to enable the Purchase Request button functionality */}
      {/* <Button
        variant="contained"
        color="success"
        startIcon={<FontAwesomeIcon icon={faShoppingCart} />}
        aria-label="Purchase Request Button"
        onClick={() => alert('Purchase Request clicked')}
        sx={{
          padding: 2,
          minWidth: 200, // Ensures uniform button width
          '&:hover': {
            backgroundColor: '#005a00', // Darker green
            color: '#ffffff',
          },
        }}
      >
        Purchase Request
      </Button> */}
    </Box>
  </Box>
);

export default Support;