import React, { useState } from 'react';
import { 
  Navbar,
  NavbarBrand, 
  Nav, 
  NavItem, 
  NavLink, 
  NavbarToggler, 
  Collapse 
} from 'reactstrap';
import './Header.css'; // Add a custom CSS file for better control over styles

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar color="light" light expand="md" className="fixed-top shadow">
      <NavbarBrand href="/" className="d-flex align-items-center">
        <img
          src="axillion-aerospace-logo.svg"
          width="150"
          className="App-logo"
          alt="Axillion Aerospace Logo"
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className="border-0" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto d-flex align-items-center" navbar>
          <NavItem>
            <NavLink href="/" className="nav-link px-3 py-2">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/ehs" className="nav-link px-3 py-2">
              EHS
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;