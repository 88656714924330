import React from 'react';
import Sections from './Sections';

const HomePage = () => {
  return (
    <main>
      <Sections />
    </main>
  );
};

export default HomePage;