import React from 'react';
import { Box, Typography } from '@mui/material';

const ContactPage = () => {
  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Contact Us
      </Typography>
      <Typography>
        This is the contact page where users can find ways to reach out to you.
      </Typography>
    </Box>
  );
};

export default ContactPage;