import React from 'react';
import { Box } from '@mui/material';
import Benefits from './Benefits';
import News from './News';
import Support from './Support';
import HelpfulLinks from './HelpfulLinks';
import KevinsKorner from './KevinsKorner';
import TimeDisplay from './TimeDisplay';

const Sections = () => (
  <Box component="main" sx={{ mt: 4 }}>
    <Box sx={{ mb: 4 }}>
      <KevinsKorner />
    </Box>
    <Box sx={{ mb: 4 }}>
      <Support />
    </Box>
    <Box sx={{ mb: 4 }}>
      <News />
    </Box>
    
    <Box sx={{ mb: 4 }}>
      <HelpfulLinks />
    </Box>
    <Box sx={{ mb: 4 }}>
      <Benefits />
    </Box>
    <Box sx={{ mb: 4 }}>
      <TimeDisplay />
    </Box>
  </Box>
);

export default Sections;