import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import './TimeDisplay.css';

// Define timezone abbreviations
const groupedLocations = [
  { timezone: 'America/New_York', abbreviation: 'EST', cities: ['Baltimore, MD', 'Rockmart, GA', 'Erlanger, KY', 'Cincinnati, OH'] },
  { timezone: 'America/Monterrey', abbreviation: 'CST', cities: ['Saltillo, Mexico'] },
  { timezone: 'America/Los_Angeles', abbreviation: 'PST', cities: ['San Diego, CA'] },
];

const TimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState({});

  useEffect(() => {
    const updateTime = () => {
      const timeData = {};
      groupedLocations.forEach(group => {
        timeData[group.timezone] = moment.tz(group.timezone).format('h:mm A');
      });
      setCurrentTime(timeData);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000); // Update every second for real-time effect

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="time-display">
      <h2>🌍 Current Time by Location</h2>
      <div className="time-groups">
        {groupedLocations.map(group => (
          <div className="time-group" key={group.timezone}>
            <div className="timezone">{group.abbreviation}</div>
            {group.cities.map(city => (
              <div className="time-card" key={city}>
                <div className="location-name">{city}</div>
                <div className="time">{currentTime[group.timezone] || 'Loading...'}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default TimeDisplay;